

.connect hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 40px;
  opacity: 1;
  margin-left: auto;
}

.connect {
  /* background-color: #f9f7f2; */
}

.connect h2 {
  white-space: nowrap;
  margin-right: 20px;
  color: #dbb85c;
  text-align: center;
}

.connect img{
    height:450px;
    object-fit: cover;
}

.connect .card{
    border: none;
    border-radius: 0;
}

.connect .owl-carousel .owl-nav button.owl-prev,
.connect .owl-carousel .owl-nav button.owl-next {
  /* border: 2px solid #d2ab66; */
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.connect .owl-carousel .owl-nav button.owl-prev span {
  position: absolute;
  left: -50px;
  font-size: 90px;
  margin-top: -400px;
  color: #d2ab66;
}

.connect .owl-carousel .owl-nav button.owl-next span {
  position: absolute;
  right: -50px;
  font-size: 90px;
  margin-top: -400px;
  color: #d2ab66;
}

.connect .owl-dots{
    display: none;
}

.connect .owl-theme .owl-nav [class*='owl-']:hover{
  background: none;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .connect h2{
    font-size: 35px;
    margin: 0 auto;
    white-space: nowrap !important;
  }
  .connect img {
    height: 350px;
  }
  .connect .owl-carousel .owl-nav button.owl-prev span {
    left: 5px;
    font-size: 65px;
    margin-top: -260px;
  }
  .connect .owl-carousel .owl-nav button.owl-next span{
    right: 5px;
    font-size: 65px;
    margin-top: -260px;
  }
  .connect .owl-carousel .owl-nav{
    position: absolute;
    width: 100%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
  .connect img {
    height: 440px;
  }
  .connect .owl-carousel .owl-nav button.owl-prev span,
  .connect .owl-carousel .owl-nav button.owl-next span{
    margin-top: -330px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
  .instagram-feed-container {
    position: relative;
    width: 90%;
    margin: 0 auto; /* Center the container */
    padding: 20px; /* Add padding */
    overflow: hidden;
  }

  .slider {
    display: flex;
    transition: transform 0.5s ease;
    white-space: nowrap; /* Ensure all slides are in a single line */
  }

  .slide {
    flex: 0 0 33.33%; /* Each slide takes up one-third of the container width */
    padding: 0 10px; /* Adjust as needed */
    box-sizing: border-box;
    height: 450px; /* Set a fixed height for each slide */
    border-radius: 10px; /* Add rounded corners to the slides */
    overflow: hidden; /* Hide overflow */
  }

  .slide img {
    width: 100%;
    height: 100%; /* Ensure the image fills the entire slide */
    object-fit: cover; /* Maintain aspect ratio */
  }

  @media (max-width: 768px) {
    .slide {
      flex: 0 0 50%; /* Each slide takes up half of the container width on smaller screens */
    }
  }

  @media (max-width: 480px) {
    .slide {
      flex: 0 0 100%; /* Each slide takes up the full container width on even smaller screens */
    }
  }

  .instagram-feed-slider {
    position: relative;
    overflow-x: hidden; /* Hide overflowing content */
  }

  .arrow-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Ensure buttons are above the slider */
  }

  .left-arrow {
    left: 10px;
  }

  .right-arrow {
    right: 10px;
  }

  .feed-image {
    width: 100%; /* Set a fixed width for the images */
    height: auto; /* Maintain aspect ratio */
  }

